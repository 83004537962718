import common from "./common";
// const ver = 'official';//正式版[显示全部导航]

//定制版[只显示 用户.用户管理 和 报表统计.用户统计]
//另外:后台管理首页只显示一个用户数量,还有右上角的修改密码需要隐藏掉
const ver = 'customized';

/**
 * 是否为正式版
 * @returns {boolean}
 * @constructor
 */
export const IsOfficial = () => {
    return ver === 'official';
};

/**
 * 基于当前版本,生成导航条
 * @param narArr
 * @returns {*}
 * @constructor
 */
export const MakeBarArr = (navArr) => {
    if (ver === 'official'){return navArr}
    let filterOptions = {
        item:['用户管理','报表统计'],
        page:['用户管理','用户统计'],
    }
    let res = [];
    let arr = common.DeepCopy(navArr);
    // console.log('bar',JSON.stringify(navArr))
    for (let arrKey in arr) {
        let temp = arr[arrKey];
        if (!filterOptions.item.includes(temp.item_name)){continue;}
        let pageArr = [];
        pageArr.push(FindPageObj(temp.list,filterOptions.page));
        temp.list = pageArr;
        res.push(temp);
    }
    // console.log('res',JSON.stringify(res))
    return res;
}

const FindPageObj = (pageArr,pageNameArr) => {
    return pageArr.find(function (obj) {
        return pageNameArr.includes(obj.page_name)
    });
}